import { useMemo } from "react";

import { useSearchParams } from "@remix-run/react";

const useHasMemberPriceFilter = () => {
  const [searchParams] = useSearchParams();
  const refineFilters = searchParams.get("refine");

  const memberPriceFacet = [["onMemberPrice:true"]];

  const hasMemberPriceFilter = useMemo(() => {
    if (!refineFilters) return false;
    return JSON.parse(refineFilters).some(
      (filter: string[]) => filter[0] === "onMemberPrice:true",
    );
  }, [refineFilters]);

  return {
    hasMemberPriceFilter,
    memberPriceFacet,
  };
};

export default useHasMemberPriceFilter;
